<template>
  <path id="Tracé_577" data-name="Tracé 577" d="M3.6,64A3.609,3.609,0,0,1,0,60.4V10.217A3.609,3.609,0,0,1,3.6,6.611H8.712V5.259a5.258,5.258,0,0,1,10.517,0V6.611H38.76V5.259a5.258,5.258,0,0,1,10.517,0V6.611h5.41a3.609,3.609,0,0,1,3.6,3.606V60.4A3.609,3.609,0,0,1,54.687,64Zm51.081-1.8v0ZM3.6,60.4h51.08V26.743H3.6Zm0-37.26h51.08V10.217H49.277V12.77a5.258,5.258,0,0,1-10.517,0V10.217H19.228V12.77a5.258,5.258,0,0,1-10.516,0V10.217H3.605ZM42.367,5.259V12.77a1.652,1.652,0,1,0,3.3,0V5.259a1.652,1.652,0,1,0-3.3,0Zm-30.048,0V12.77a1.652,1.652,0,1,0,3.3,0V5.259a1.652,1.652,0,1,0-3.3,0Zm28.7,46.949V47.249h4.958v4.959Zm-10.219,0V47.249h4.958v4.959Zm-9.842,0V47.249h4.959v4.959Zm-9.841,0V47.249h4.958v4.959Zm30.2-11.944V35.305h4.958v4.959Zm-10.219,0V35.305h4.956v4.959Zm-9.842,0V35.305h4.958v4.959Zm-9.841,0V35.305h4.958v4.959Z" transform="translate(0.5 0.499)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconPlanning"
}
</script>

<style scoped>

</style>